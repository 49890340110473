import React from 'react';
import photo from '../static/Footer-Img.jpg';
import './Footer.css';

function Footer() {
    var width = document.documentElement.clientWidth;
    const temp = [       
            <a href="https://www.linkedin.com/in/brian-hughes-88085316a"aria-label="linked-in profile link">
                <i aria-hidden="true" className="fa fa-linkedin Footer-Link"></i>
            </a>,
            <a href="https://gitlab.com/hughesbrian" aria-label="gitlab profile link">
                <i aria-hidden="true" className="fa fa-gitlab Footer-Link"></i>
            </a>,
            <a href={"mailto:hughesbrian@protonmail.com"} aria-label="email link">
                <i aria-hidden="true" className="fa fa-envelope Footer-Link"></i>
            </a>,
        <img className="Footer-Img" src={photo} alt="decorative jellyfish footer image"></img>]
    if (width < 600){	
        return(
        <footer className="Footer mobile" id="Footer">
            {temp}
        </footer>)
    }
    else{
    return (			
        <footer className="Footer" id="Footer">
            {temp}
        </footer>)
    }
}

export default Footer;