import React from 'react';
import photo from '../static/Header-Img.jpg';
import './Header.css';

function Header(props) {
  var width = document.documentElement.clientWidth;
  const blurbContents = [];
  for(var i = 0; i < props.blurb.length; i++) {
    blurbContents.push(<p>{props.blurb[i]}</p>)
  }
  const temp = [<div className="Header-content">
              <img className="Header-Img" src={photo} alt="decorative jellyfish header image"></img>
              <h1>{props.title}</h1>
              {blurbContents}
              <p>To contact me please feel free to reach out at hughesbrian@protonmail.com or at my <a href="https://www.linkedin.com/in/brian-hughes-88085316a\">LinkedIn</a>. The repo for this website can be found at my <a href="https://gitlab.com/hughesbrian">GitLab</a>. I look forward to speaking with you!</p>
              </div>]
  if (width < 600){
      return (
        <header className="Header mobile" id="Header">
          {temp}
        </header>
      );
  }
  else{
    return (
      <header className="Header" id="Header">
        {temp}
      </header>
    );
  }

}

export default Header;