import React from 'react';
import './Picross.css';
import topPhoto from '../static/Header-Img.jpg';
import bottomPhoto from '../static/Footer-Img.jpg';

function Square(props) {
    return (
      <button 
      className= {(props.value === '') ? "filled-in-square" : "square"} 
      onClick={props.onClick}>
        {props.value}
      </button>
    );
  }

  function Label(props) {
    return (
      <div className="square-label">
        {props.value}
      </div>
    );
  }

  //keeping this around just in case
  function generateSolution(height, width){
      var solution = Array(height * width).fill(' ')
      for(let i = 0; i < height; i++){
          for(let j = 0; j < width; j++){
            solution[(i * width) + j] = Math.round(Math.random() * 1.15);
          }
      }
      return solution
  }
  
  function generateHorizontalLabels(height, width, solution){
    var labels = Array(height).fill([])
    for(let i = 0; i < height; i++){
        var total = 0;
        var currrowlabels = Array();
        for(let j = 0; j < width; j++){
          if(solution[(i * width) + j] === 1){
                total++;
          }
          if((solution[(i * width) + j] === 0 || j === width - 1) && total !== 0){
              currrowlabels.push(total)
              total = 0;
              continue;
          }
        }
        if(currrowlabels.length == 0){
            currrowlabels.push(0)
        } 
        labels[i] = currrowlabels
    }
    return labels
    }

  function generateVerticalLabels(height, width, solution){
    var labels = Array(width).fill([])
    for(let i = 0; i < width; i++){
        var total = 0;
        var currcollabels = Array();
        for(let j = 0; j < height; j++){
          if(solution[(j * width) + i] === 1){
                total++;
          }
          if((solution[(j * width) + i] === 0 || j === height - 1) && total !== 0){
              currcollabels.push(total)
              total = 0;
              continue;
          }
          }
          if(currcollabels.length == 0){
            currcollabels.push(0)
          } 
        labels[i] = currcollabels
    }
    return labels
    }


  class Board extends React.Component {
    constructor(props) {
      super(props);
      var solution = props.solution
      var solutionLabels = {
          horizontalLabels: generateHorizontalLabels((props.dimensions[1]/props.blocksize),(props.dimensions[0]/props.blocksize), solution),
          verticalLabels: generateVerticalLabels((props.dimensions[1]/props.blocksize),(props.dimensions[0]/props.blocksize), solution)
      }
      this.state = {
        length: (props.dimensions[0]/props.blocksize),
        height: (props.dimensions[1]/props.blocksize),
        squaresize: props.blocksize,
        squares: Array((props.dimensions[0]/props.blocksize) * (props.dimensions[1]/props.blocksize)).fill(' '),
        solution: solution,
        solutionLabels: solutionLabels,
        horizontalLabelMax: solutionLabels.horizontalLabels.reduce((curr,prev) => curr.length > prev.length ? curr : prev).length,
        verticaltabelLabelMax: solutionLabels.verticalLabels.reduce((curr,prev) => curr.length > prev.length ? curr : prev).length,
        status: "in-progress"
      };
    }
  
    handleClick(i) {
      const squares = this.state.squares.slice();
      if(squares[i] === 'X'){
          squares[i] = ' '
      }
      else if(squares[i] === ' '){
        squares[i] = ''
      }
      else if(squares[i] === ''){
        squares[i] = 'X'
      }
      this.setState({
        squares: squares
      });
    }
  
    renderSquare(i) {
      return (
        <Square
          value={this.state.squares[i]}
          onClick={() => this.handleClick(i)}
        />
      );
    }

    renderLabel(labelval) {
        return (
          <Label
            value={labelval}
          />
        );
      }

    checkAnswer(){
        for(let i = 0; i < this.state.squares.length; i++){
            if((this.state.squares[i] === '' && this.state.solution[i] !== 1) ||
                (this.state.squares[i] === ' ' && this.state.solution[i] !== 0) ||
                (this.state.squares[i] === 'X' && this.state.solution[i] !== 0)){
                    this.setState({
                        status: "Not quite there..."
                      });
                    return
            }
        }
        this.setState({
            status: "Solved!"
        });
    }
    render() {
      var board= [];
      for(let i = -this.state.verticaltabelLabelMax; i < this.state.height; i++){
          var row = [];
          for(let j = -this.state.horizontalLabelMax; j < this.state.length; j++){
              if(i < 0 && j < 0){
                row.push(this.renderLabel(' '))
              }
              else if(i < 0){
                if(this.state.solutionLabels.verticalLabels[j][i + this.state.verticaltabelLabelMax + (this.state.solutionLabels.verticalLabels[j].length - this.state.verticaltabelLabelMax)] !== undefined){
                    row.push(this.renderLabel(this.state.solutionLabels.verticalLabels[j][i + this.state.verticaltabelLabelMax + (this.state.solutionLabels.verticalLabels[j].length - this.state.verticaltabelLabelMax)]))
                }
                else{
                    row.push(this.renderLabel(' '))
                }
              }
              else if(j < 0){
                if(this.state.solutionLabels.horizontalLabels[i][(j + this.state.horizontalLabelMax) + (this.state.solutionLabels.horizontalLabels[i].length - this.state.horizontalLabelMax)] !== undefined){
                    row.push(this.renderLabel(this.state.solutionLabels.horizontalLabels[i][(j + this.state.horizontalLabelMax + (this.state.solutionLabels.horizontalLabels[i].length - this.state.horizontalLabelMax))]))
                }
                else{
                    row.push(this.renderLabel(' '))
                }
              }
              else{
                row.push(this.renderSquare((i * this.state.length)+ j));
              }
          }
          board.push(<div className="board-row">
          {row}
          </div>)

      }
      document.documentElement.style.setProperty('--square-size', this.state.squaresize + "px")
      document.documentElement.style.setProperty('--font-size', this.state.squaresize * 0.75 + "px")
      return (
        <div className='ArtSection'>
          {board}
          <div className='GameText'>
            <p>{this.state.status}</p>
            <button className='SubmitButton' onClick={() => this.checkAnswer()}>check answer</button>
          </div>
        </div>
      );
    }
  }
  
  class PicrossGame extends React.Component {
    render() {
      const { props, state } = this;
      return (
        <div className='ArtPage'>
		<img className="Header-Img-Blog" src={topPhoto} alt="decorative jellyfish header image"></img>
        <div className="game">
        <h1>Picross Game</h1>
          <div className="game-board">
            <Board dimensions={props.dimensions}
                   solution={props.solution}
                   blocksize={props.blocksize}/>
          </div>
        </div>
		<img className="Footer-Img-Blog" src={bottomPhoto} alt="decorative jellyfish footer image"></img>
        </div>
      );
    }
  }
  
  


export default PicrossGame;