import React, { useEffect, useState } from 'react';
import { useParams} from 'react-router-dom';
import './Blog.css';
import topPhoto from '../static/Header-Img.jpg';
import bottomPhoto from '../static/Footer-Img.jpg';
import localposts from'./posts.json';
import ReactMarkdown from 'react-markdown'

function BlogEntry() {
    const {id} = useParams()
    const [post, setPost] = useState([])
    useEffect(() => {
            getPost();
      }, []);
    const getPost = async () => {
       setPost(localposts[id])
    }
    if(post.content == null){
        return null;
    }
    const temp = [];
    temp.push(<div className='BlogEntryTitle'>
                <img className="Header-Img-Blog" src={topPhoto} alt="decorative jellyfish header image"></img>
                <img className="Footer-Img-Blog" src={bottomPhoto} alt="decorative jellyfish footer image"></img>
                </div>)
    temp.push(<div className='BlogEntry'><ReactMarkdown>{post.content}</ReactMarkdown></div>)
    return temp;
}

export default BlogEntry;