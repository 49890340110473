import React from 'react';
import photo from '../static/BrianHughes2.jpg';
import './SideBar.css';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';


class SideBar extends React.Component{
  constructor(props){
    super(props);
    var width = document.documentElement.clientWidth
    var removed = false
    if(width < 600){
      removed = true
    }
    this.state = {
      removed: removed,
      prev_width: width
    }
  }
  render(){
    const name = "brianehughes"
    const organization = "protonmail"
    const domain = "com"
    const temp = [
                <h2>BRIAN HUGHES</h2>,
                <img className="SideBar-Img" src={photo} alt="" width="200" height="200"></img>,
                <nav className="SideBar-Nav">
                <HashLink smooth to="#ProjectSection">Projects</HashLink>
                <HashLink smooth to="#TimelinePage">Experience</HashLink>
                </nav>,
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>];
    var width = document.documentElement.clientWidth;
    if (width < 600){
      return (
        <aside className="SideBar mobile" id="SideBar">
        </aside>
      );
    }
    else{
      return (
        <aside className="SideBar" id="SideBar">
          {temp}
        </aside>
      );
    }
  }
}
export default SideBar;
