import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import SideBar from './sidebar/SideBar';
import Header from './header/Header';
import Footer from './footer/Footer';
import ProjectSection from './project-section/ProjectSection';
import Timeline from './timeline/Timeline';
import {BrowserRouter, Route} from "react-router-dom";
//hidding links to blog and picross game because they are not ready yet
import Blog from './blog/Blog';
import Picross from './picross/Picross';
import PicrossGame from './picross/PicrossGame';
import BlogEntry from './blog/BlogEntry';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Route path={"/"} exact={true}>
        <SideBar />

          <Header title="WELCOME" blurb={[ "I’m Brian Hughes, a Philadelphia based DevOps Engineer working remote for DonorDrive. I’m passionate about DevOps, IaC, CI/CD pipelines, and scripting; anything I can do to automate processes for myself or my teammates is what excites me!",
                                            "In my free time I like to spend time with my wife Alex and my cats Celeste, Dandelion, Eva, and French Fry. I also enjoy gardening, cooking, and keeping up with video games."]}
          />
          <Timeline timelineitems={[
              {
                time: "August 2022",
                title: "Junior Site Reliability Engineer ",
                company: "DonorDrive",
                desc:
                [
                  "Worked directly with lead DevOps Engineer and CTO to manage infrastructure across all AWS accounts and Cloudflare, using Terraform for IaC",
                  "Participated in the on-call rotation and independently resolved production issues",
                  "Updated CloudWatch alarms and CloudWatch agent configurations when additional infrastructure was provisioned",
                  "Assisted in the cloud migration for our Bitbucket environment and created a Cloudflare worker maintenance page during the  migration period",
                  "Led the migration and standardization of Cloudflare rules leveraging Terraform and Bash",
                  "Gathered PCI-DSS evidence, and participated in demos & interviews during the audit process",
                  "Created and managed ECR repositories for both development images and internal tools",
                  "Wrote AWS Lambda functions in Python to automate weekly tasks including cleanup of unused AMIs and database health checks",
                  "Setup EC2 runners to automate OWASP ZAP scans following PCI requirements and daily refreshes of our masked database utilizing Python scripts",
                  "Ran OS updates on the entire infrastructure including overnight production database patches"
                ]
              },
              {
                time: "July 2020",
                title: "DevOps Engineer I",
                company: "PowerAdvocate",
                desc:
                [
                  "Started as a Co-op from July 2020 to April 2021, returned in June 2021 as a DevOps Engineer",
                  "Led the development of a DevOps team documentation including releases, OS updates, hiring, infrastructure architecture, and onboarding process",
                  "Responsible for DevOps Co-op program which included reviewing resumes and interviewing candidates",
                  "Managed 4 Co-ops and conducted weekly 1 on 1 meetings to develop career goals and provide mentorship ",
                  "Independently ran deployments of all applications in PowerAdvocate",
                  "Implemented updates to our ECS deployment automation tool to add new microservices",
                  "Tightened IAM permissions for microservices to reduce operational risk",
                  "Served as the point of contact between Data Science and DevOps teams",
                  "Migrated Artifactory from on-premises to AWS"]
              },
              {
                time: "July 2019 - December 2019",
                title: "DevOps Co-op",
                company: "Wanderu",
                desc:
                [
                  "Updated Icinga and AWS SNS monitoring systems to improve site reliability and application health",
                  "Implemented pilot and production auto-deployments through a Python deployment application",
                  "Created nginx rewrites and updated configurations"
                  ]
              }
            ]}/>
          <Footer/>
                </Route>
        <Route path="/blog/" exact={true}>
          <Blog/>
        </Route>
        <Route path="/artwork/" exact={true}>
          <Picross/>
        </Route>
        <Route path="/blog/:id" exact={true}>
        <BlogEntry/>
        </Route>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
