import axios from 'axios';
import {useEffect, useState, React} from 'react';
import topPhoto from '../static/Header-Img.jpg';
import bottomPhoto from '../static/Footer-Img.jpg';
import './Picross.css';
import artworkIDs from './ArtworkIDs'
import PicrossGame from './PicrossGame';

//grabed this function from https://stackoverflow.com/questions/11442712/get-width-height-of-remote-image-from-url

const getMeta = (url, cb) => {
	const img = new Image();
	img.onload = () => cb(null, img);
	img.onerror = (err) => cb(err);
	img.crossOrigin = "Anonymous";
	img.src = url;
};

const blocksize = 40

const getDimensions = async (url) => {
	var dimensions = []
	await getMeta(url, (err, img) => {
		if (img.width != 0 && img.height != 0){
			dimensions.push(roundBlock(img.width, blocksize))
			dimensions.push(roundBlock(img.height, blocksize))
			document.documentElement.style.setProperty('--image-width', dimensions[0] + "px")
			document.documentElement.style.setProperty('--image-height', dimensions[1] + "px")
		}
	});
	return dimensions
}

const roundBlock = (num, blocksize) => {
	return Math.floor(num / blocksize) * blocksize
}

//https://stackoverflow.com/questions/17714742/looping-through-pixels-in-an-image
const generatePuzzleSolution = (data, blocksize, dimensions) => {
	var solution = Array( (dimensions[0] / blocksize) * (dimensions[1] / blocksize)).fill(' ')
	var blockpixelvalues = Array((dimensions[0] / blocksize) * (dimensions[1] / blocksize)).fill(0)
	var currrow = 0
	for(let i = 0; i < data.length; i += 4){
		const red = data[i];
		const green = data[i + 1];
		const blue = data[i + 2];
		let pixelindex = i/4
		blockpixelvalues[(currrow * dimensions[0] /blocksize) + Math.floor((pixelindex % dimensions[0])/ blocksize)] += red + blue + green
		if (pixelindex % (dimensions[0] * blocksize) === 0 && i != 0){
			currrow = currrow + 1
		}
	}
	//https://stackoverflow.com/questions/29544371/finding-the-average-of-an-array-using-js
	const average = blockpixelvalues.reduce((a, b) => a + b) / blockpixelvalues.length;
	for (let i = 0; i < blockpixelvalues.length; i++){
		if (blockpixelvalues[i] < average){
			solution[i] = 1
		}
		else{
			solution[i] = 0
		}
	}
	return solution
}

const Picross=()=> {
	const [imagedata, setImageData] = useState([]);
	useEffect(() => {
		getImageData();
  	}, []);
	var result = [];

	const getImageData = async () => {
		try{	
			//var artID = artworkIDs[Math.floor(Math.random() * artworkIDs.length)];
    		var artID = artworkIDs[Math.floor((new Date().getTime() - new Date("01/22/2023").getTime()) / (1000 * 3600 * 24))];
			var manifestresult = await axios.get('https://api.artic.edu/api/v1/artworks/' + artID + '/manifest.json', {
										  headers: {'AIC-User-Agent': 'brian hughes personal website project at brianhughes.me (contact: hughesbrian@protonmail.com)'}})
										  .then(res => res.data);
			var dimensions = await getDimensions(manifestresult.sequences[0].canvases[0].images[0].resource['@id']).then(res => res)
			var artworkresult = await axios.get('https://api.artic.edu/api/v1/artworks/' + artID, {
											headers: {'AIC-User-Agent': 'brian hughes personal website project at brianhughes.me (contact: hughesbrian@protonmail.com)'}})
											.then(res => res.data);
		}
		catch (error){
			console.log(error)
		}
		setImageData({manifest: manifestresult,
					  artwork: artworkresult,
					  dimensions: dimensions})
    }
	
	//to do deal with &quot;squatting-bear&quote
	if(imagedata.manifest === undefined || imagedata.artwork === undefined || imagedata.dimensions === undefined || imagedata.dimensions === []){
		result.push(<div className='ArtPage'>
						<img className="Header-Img-Blog" src={topPhoto} alt="decorative jellyfish header image"></img>
							<div className='ArtSection'>
								<h1>A Random Piece of Artwork</h1>
								<h3>Loading... </h3>
							</div>
						<img className="Footer-Img-Blog" src={bottomPhoto} alt="decorative jellyfish footer image"></img>
					</div>)
		return result;
	}
	var img = new Image();
	var data;
	var canvas = document.createElement('canvas')
	var context = canvas.getContext('2d');
	img.crossOrigin = "Anonymous";
	img.src = imagedata.manifest.sequences[0].canvases[0].images[0].resource['@id']
	canvas.width = imagedata.dimensions[0];
	canvas.height = imagedata.dimensions[1];
	context.drawImage(img, 0, 0, imagedata.dimensions[0], imagedata.dimensions[1]);
	const imgData = context.getImageData(0, 0, canvas.width, canvas.height);
	data = imgData.data;
	result.push(<div>
				<div className='ArtPage'>
					<img className="Header-Img-Blog" src={topPhoto} alt="decorative jellyfish header image"></img>
					<div className='ArtSection'>
						<h1>A Random Piece of Artwork</h1>
						<div className='ArtDisplay'>
							<img src={imagedata.manifest.sequences[0].canvases[0].images[0].resource['@id']} alt={imagedata.artwork.data.thumbnail.alt_text} className="art-image"></img>
							<h4>{imagedata.manifest.sequences[0].canvases[0].label}</h4>
							<p>{imagedata.manifest.description[0].value}</p>
							<br></br>
							<div className='AttributionText'>
								<p>Thank you to The Art Institute of Chicago® for providing the image and description for the artwork through their API,
									the documentation of which can be found <a href='https://api.artic.edu/docs/#introduction'>here</a>. All images are in the public domain and are presented under fair use. Below is a generated picross game based on the above artwork, please enjoy!</p>
									
							</div>
						</div>
					</div>
					<img className="Footer-Img-Blog" src={bottomPhoto} alt="decorative jellyfish footer image"></img>
				</div>
				<PicrossGame
					dimensions={imagedata.dimensions}
					blocksize={blocksize}
					solution={generatePuzzleSolution(data, blocksize, imagedata.dimensions)}/></div>)
	return result
}

export default Picross;